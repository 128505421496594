import request from '@/utils/request'

// api地址
const api = {
  all: '/frm/corpUser',
  save: '/frm/corpUser',
  update: '/frm/corpUser/update',
  delete: '/frm/corpUser/',
  deleteBatch: '/frm/corpUser/batch',
  page: '/frm/corpUser/page',
  import: '/frm/corpUser/import/',
  fieldList: '/frm/customField',
  applyFieldList: '/frm/customField/apply',
  info: 'frm/customField/',
  saveField: '/frm/customField',
  saveApplyField: '/frm/customField/apply',
  updateField: '/frm/customField/update',
  deleteField: '/frm/customField/',
  fieldVerify: 'frm/customField/verify/',
  fieldEnable: 'frm/customField/enable/',
  exportForm: 'frm/corpUser/export/',
  infoFormData: 'frm/corpUser/infoFormData',
  agree: '/frm/corpUserApply/agree',
  refuse: '/frm/corpUserApply/refuse'
}
// 通过认证
export function agree(data) {
  return new Promise((resolve, reject) => {
    request.post(api.agree, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 驳回认证
export function refuse(data) {
  return new Promise((resolve, reject) => {
    request.post(api.refuse, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 导出
export function exportForm(id, data) {
  const config = {
    responseType: 'blob'
  }
  return new Promise((resolve, reject) => {
    request.post(api.exportForm + id, data, config).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询所有
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function info(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
// 字段是否启用
export function fieldEnable(id, data) {
  return new Promise((resolve, reject) => {
    request.post(api.fieldEnable + id, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 字段是否禁用
export function fieldVerify(id, data) {
  return new Promise((resolve, reject) => {
    request.post(api.fieldVerify + id, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改自定义字段
 * @param data 参数
 * @returns {Promise<>}
 */
export function saveField(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.updateField, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.saveField, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteFieldById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteField + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function importCorpUser(id, data) {
  return new Promise((resolve, reject) => {
    request.post(api.import + id, data, {
      'content-type': 'multipart/form-data'
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function fieldList(params) {
  return new Promise((resolve, reject) => {
    request.get(api.fieldList, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function applyFieldList(params) {
  return new Promise((resolve, reject) => {
    request.get(api.applyFieldList, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

export function saveApplyField(data) {
  return new Promise((resolve, reject) => {
    request.post(api.saveApplyField, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

// 获取审批数据
export function infoFormData(data) {
  return new Promise((resolve, reject) => {
    request.post(api.infoFormData, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
