<template>
  <a-modal
    :width="900"
    title="成员选择"
    :body-style="{ paddingBottom: '8px' }"
    :confirm-loading="loading"
    :visible="visible"
    @update:visible="updateVisible"
    @ok="selectConfirm"
    :zIndex="999"
  >
    <a-form
      :model="where"
      :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
    >
      <a-row>
        <a-col :lg="20" :md="24" :sm="24" :xs="24">
          <a-form-item label="搜索字段:" :labelCol="{ span: 1.5, offset: 0 }">
            <a-select
              mode="multiple"
              v-model:value="addField"
              @change="fieldChange"
              placeholder="请选择搜索字段"
              allow-clear
            >
              <a-select-option
                v-for="(item, index) in fieldList"
                :key="index"
                :value="index"
                >{{ item.label }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="4" :md="12" :sm="24" :xs="24">
          <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :lg="6"
          :md="12"
          :sm="24"
          :xs="24"
          v-for="item in customField"
          :key="item.fieldId"
        >
          <a-form-item :label="item.label">
            <a-input
              v-if="item.type === '单行文本'"
              v-model:value.trim="where[item.fieldId]"
              placeholder="请输入"
              allow-clear
            />
            <a-input
              v-if="item.type === '手机号'"
              v-model:value.trim="where[item.fieldId]"
              placeholder="请输入手机号"
              allow-clear
            />
            <a-select
              v-if="item.type === '下拉框'"
              v-model:value="where[item.fieldId]"
              placeholder="请选择"
              allow-clear
            >
              <a-select-option
                v-for="(value, index) in strToArr(item)"
                :key="index"
                :value="value"
                >{{ value }}</a-select-option
              >
            </a-select>
            <a-radio-group
              v-if="item.type === '单项选择'"
              v-model:value="where[item.fieldId]"
            >
              <a-row>
                <a-col
                  :span="12"
                  v-for="(value, index) in strToArr(item)"
                  :key="index"
                >
                  <a-radio :value="value">{{ value }}</a-radio>
                </a-col>
              </a-row>
            </a-radio-group>
            <a-checkbox-group
              v-if="item.type === '多项选择'"
              v-model:value="where[item.fieldId]"
            >
              <a-row>
                <a-col
                  :span="12"
                  v-for="(value, index) in strToArr(item)"
                  :key="index"
                >
                  <a-checkbox :value="value">{{ value }}</a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
            <a-input-number
              v-if="item.type === '数字'"
              v-model:value="where[item.fieldId]"
              placeholder="请输入"
              allow-clear
            ></a-input-number>
            <a-textarea
              v-if="item.type === '多行文本'"
              allow-clear
              v-model:value="where[item.fieldId]"
              placeholder="请输入"
            ></a-textarea>
            <a-date-picker
              v-if="item.type === '日期'"
              v-model:value="where[item.fieldId]"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <!-- 表格 -->
    <ud-pro-table
      ref="tableMember"
      row-key="id"
      :toolbar="false"
      :where="where"
      selectionType="checkbox"
      :customRow="customRowClick"
      :datasource="datasource"
      :columns="columns"
      v-model:selection="selection"
      :scroll="{ x: 'max-content' }"
    >
      <template #img="{ text }">
        <div class="image">
          <a-image
            :src="text + '?width=120'"
            :width="120"
            :height="90"
          ></a-image>
        </div>
      </template>
    </ud-pro-table>
  </a-modal>
</template>

<script>
import * as corpUserApi from '@/api/corp/corpUser.js'
export default {
  props: {
    visible: Boolean,
    corpId: Number,
    memberSelection: Array
  },
  data() {
    return {
      loading: false,
      formColums: [],
      fieldList: [],
      selection: this.memberSelection,
      customField: [
        {
          fieldId: 'frmA',
          label: '序号',
          type: 'text'
        }
      ],
      defaultField: [],
      addField: [],
      where: {},
      columns: [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.tableMember.tableIndex + index
        },
        {
          title: '姓名',
          dataIndex: 'nickname',
          sorter: true
        },
        {
          title: '性别',
          dataIndex: 'sex',
          sorter: true
        },
        {
          title: '手机号',
          dataIndex: 'username',
          sorter: true
        },
        {
          title: '邮箱',
          dataIndex: 'email',
          sorter: true
        }
      ]
    }
  },
  watch: {
    corpId() {
      if (this.corpId) {
        this.queryFormField()
        this.reload()
      }
    },
    // selection: {
    //   handler(nval, oval) {
    //     console.log({ nval, oval })
    //   },
    //   deep: true
    // },
    memberSelection: {
      handler(nval, oval) {
        this.selection = this.memberSelection
        // console.log({ memberSelection: nval, memberSelections: oval })
      },
      deep: true
    }
  },
  mounted() {
    this.queryFormField()
    this.reload()
    console.log('corpId', this.corpId)
  },
  methods: {
    initSelectData() {
      this.addField = []
      this.customField = []
      this.columns = [
        {
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.tableMember.tableIndex + index
        },
        {
          title: '状态',
          dataIndex: 'state',
          sorter: true
        },
        {
          title: '操作',
          slots: {
            customRender: 'action'
          },
          sorter: true
        }
      ]
    },
    customRowClick(record) {
      const that = this
      return {
        onclick() {
          const index = that.selection.findIndex((item) => {
            return item.id === record.id
          })
          if (index > -1) {
            that.selection.splice(index, 1)
          } else {
            that.selection.push(record)
          }
          // console.log('selection', that.selection)
        }
      }
    },
    // 选中字段改变
    fieldChange(e) {
      this.customField = []
      e.forEach((index) => {
        this.customField.push(this.fieldList[index])
      })
    },
    strToArr(item) {
      const str = item.listData
      if (str) {
        const arr = str.split(',')
        return arr
      } else {
        return []
      }
    },
    arrToStr(arr) {
      if (arr.length > 0) {
        const str = arr.join(',')
        return str
      } else {
        return ''
      }
    },
    selectConfirm() {
      this.$emit('done', this.selection)
    },
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    formatWhere() {
      const filter = {}
      this.fieldList.forEach((item) => {
        if (
          item.type === '多项选择' ||
          item.type === '下拉框' ||
          item.type === '标签'
        ) {
          if (this.where[item.fieldId] === undefined) {
            filter[item.fieldId] = []
          } else {
            filter[item.fieldId] = this.arrToStr(this.where[item.fieldId])
          }
        } else {
          filter[item.fieldId] = this.where[item.fieldId]
        }
      })
      return filter
    },
    datasource(option, callback) {
      const filter = this.formatWhere(this.where)
      corpUserApi
        .page({
          corpId: this.corpId,
          ...filter,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 搜索 */
    reload() {
      this.selection = []
      const filter = this.formatWhere()
      this.$nextTick(() => {
        this.$refs.tableMember.reload({ page: 1, where: filter })
      })
    },
    /*  重置搜索 */
    reset() {
      this.where = {}
      this.queryFormField()
      this.reload()
    },
    // 查询
    resetColums() {
      this.columns = this.formColums.concat([
        {
          title: '操作',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ])
      this.reload()
    },
    queryFieldList(arr) {
      this.where = {}
      this.addField = []
      this.customField = []
      this.fieldList = arr.filter((item) => {
        return {
          label: item.label,
          fieldId: item.fieldId,
          type: item.type,
          listData: item.listData
        }
      })
      const defaultField = ['name', 'phone', 'sex']
      defaultField.forEach((item) => {
        const index = this.fieldList.findIndex((value) => {
          return value.fieldId === item
        })
        if (index !== -1) {
          this.addField.push(index)
        }
      })
      this.addField.forEach((index) => {
        this.customField.push(this.fieldList[index])
      })
    },
    // 重置列表
    queryFormField() {
      const that = this
      corpUserApi
        .fieldList({
          formId: 'corp-' + this.corpId,
          enable: true,
          sort: 'sortNumber'
        })
        .then((res) => {
          if (res.code === 0) {
            that.formColums = [
              {
                key: 'index',
                width: 48,
                customRender: ({ index }) =>
                  this.$refs.tableMember.tableIndex + index
              },
              {
                title: '状态',
                dataIndex: 'state',
                sorter: true
              }
            ]
            for (let i = 0; i < res.data.length; i++) {
              if (
                res.data[i].type === '单张图片' ||
                res.data[i].type === '多张图片'
              ) {
                that.formColums.push({
                  title: res.data[i].label,
                  dataIndex: res.data[i].fieldId,
                  slots: { customRender: 'img' }
                })
              } else {
                that.formColums.push({
                  title: res.data[i].label,
                  dataIndex: res.data[i].fieldId,
                  sorter: true
                })
              }
            }
            this.queryFieldList(res.data)
            that.resetColums()
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}
</style>
