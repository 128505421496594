import request from '@/utils/request'

// api地址
const api = {
  info: '/frm/activityInfo/notice/',
  save: '/frm/activityInfo/notice',
  update: '/frm/activityIfno/notice/update'
}

/**
 * 查询
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function info(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
