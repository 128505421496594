<template>
  <a-card :bordered="false">
    <a-form
      :model="where"
      :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
    >
      <a-row>
        <a-col :lg="5" :md="12" :sm="24" :xs="24">
          <a-form-item label="姓名:">
            <a-input
              v-model:value.trim="where.name"
              placeholder="请输入姓名"
              allow-clear
            />
          </a-form-item>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :xs="24">
          <a-form-item label="手机号:">
            <a-input
              v-model:value.trim="where.phone"
              placeholder="请输入手机号"
              allow-clear
            />
          </a-form-item>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :xs="24">
          <a-form-item label="状态:">
            <a-select
              v-model:value="where.state"
              placeholder="请选择状态"
              allow-clear
            >
              <a-select-option
                v-for="item in regStateList"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :xs="24">
          <a-form-item label="付款状态:">
            <a-select
              v-model:value="where.payState"
              placeholder="请选择付款状态"
              allow-clear
            >
              <a-select-option
                v-for="item in payStateList"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :lg="4" :md="12" :sm="24" :xs="24">
          <a-form-item class="ud-text-right" :wrapper-col="{ span: 24 }">
            <a-space>
              <a-button type="primary" @click="reload">查询</a-button>
              <a-button @click="reset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div>
      <ud-pro-table
        ref="tableReg"
        row-key="regId"
        :datasource="datasource"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
      >
        <template #action="{ record }">
          <a-space>
            <a-popconfirm
              title="确定要取消吗，如有报名费用将一并退款？"
              @confirm="cancel(record)"
            >
              <a class="ud-text-primary">取消报名</a>
            </a-popconfirm>
          </a-space>
        </template>
        <template #index="{ index }">
          {{ index + 1 }}
        </template>
        <template #img="{ record }">
          <img :src="record.avatar" width="50" height="50" />
        </template>
        <template #toolbar>
          <a-space>
            <a-button type="info" @click="openRegSetting"
              >设置填写表单</a-button
            >
            <a-button type="primary" @click="exportactivityUserReg"
              >导出</a-button
            >
          </a-space>
        </template>
      </ud-pro-table>
    </div>
    <a-modal
      :width="1200"
      v-model:visible="showRegSetting"
      :confirm-loading="loading"
      @ok="saveTemplate"
    >
      <!-- 费用信息弹窗 -->
      <reg-setting
        ref="regSet"
        @done="doneRegSetting"
        :activityId="activityId"
        :formId="formId"
      />
    </a-modal>
  </a-card>
</template>

<script>
import * as activityRegApi from '@/api/activity/activityUserReg.js'
import * as dictApi from '@/api/dict/dictData.js'
import * as activityFormFieldApi from '@/api/activity/activityFormField.js'
import { toDateString } from '@/utils/util.js'
import RegSetting from './setting'
export default {
  emits: ['done', 'update:visible'],
  props: {
    activityId: Number,
    formId: Number
  },
  components: {
    RegSetting
  },
  watch: {
    activityId() {
      if (this.activityId) {
        this.queryFormField()
        this.reload()
      }
    }
  },
  data() {
    return {
      name: 'reg-index',
      // 表格列配置
      columns: [
        {
          key: 'index',
          width: 48,
          slots: { customRender: 'index' }
        },
        {
          title: '状态',
          dataIndex: 'state',
          sorter: true
        },
        {
          title: '付款金额',
          dataIndex: 'payPrice',
          sorter: true
        },
        {
          title: '付款时间',
          dataIndex: 'payTime',
          sorter: true
        },
        {
          title: '付款状态',
          dataIndex: 'payState',
          sorter: true
        },
        {
          title: '报名时间',
          dataIndex: 'createTime',
          sorter: true
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 表格搜索条件
      where: {},
      regStateList: [],
      payStateList: [],
      formColums: [],
      showRegSetting: false,
      activityInfo: {}
    }
  },
  mounted() {
    this.queryRegState()
    this.queryPayState()
    this.queryFormField()
  },
  methods: {
    // 保存模板数据
    saveTemplate() {
      const fieldList = this.$refs.regSet.fieldList.map((item) => {
        item.formId = this.formId
        return item
      })
      const removeIds = this.$refs.regSet.removeIds
      // console.log('fieldList', fieldList)
      activityFormFieldApi
        .save({ fieldList, removeIds })
        .then((res) => {
          if (res.code === 0) {
            this.showRegSetting = false
            this.queryFormField()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
      // console.log('fieldList', this.$refs.fieldTem.fieldList)
    },
    handleCancel() {
      this.queryFormField()
    },
    datasource(option, callback) {
      activityRegApi
        .page({
          ...this.where,
          activityId: this.activityId,
          order: 'desc',
          sort: 'createTime',
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            console.log('本', res.data)
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.$refs.tableReg.reload({ page: 1 })
    },
    reset() {
      this.where = {}
      this.$nextTick(() => {
        this.reload()
      })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 查询报名状态
    queryRegState() {
      dictApi
        .getDictData({ dictCode: 'applyState' })
        .then((res) => {
          if (res.code === 0) {
            this.regStateList = res.data
          } else {
            this.$message.error(res.data)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询支付状态
    queryPayState() {
      dictApi
        .getDictData({ dictCode: 'payState' })
        .then((res) => {
          if (res.code === 0) {
            this.payStateList = res.data
          } else {
            this.$message.error(res.data)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    exportactivityUserReg() {
      activityRegApi
        .exportActivityUserReg(this.activityId, {
          ...this.where
        })
        .then((res) => {
          const { data, headers } = res
          const fileName =
            '报名信息-' + toDateString(new Date(), 'MMddHHmmss') + '.xlsx' // headers['content-disposition'].replace(/\w+;filename=(.*)/, '$1')
          // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
          // const blob = new Blob([JSON.stringify(data)], ...)
          const blob = new Blob([data], { type: headers['content-type'] })
          const dom = document.createElement('a')
          const url = window.URL.createObjectURL(blob)
          dom.href = url
          dom.download = decodeURI(fileName)
          dom.style.display = 'none'
          document.body.appendChild(dom)
          dom.click()
          dom.parentNode.removeChild(dom)
          window.URL.revokeObjectURL(url)
        })
        .catch((e) => {})
    },
    cancel(item) {
      activityRegApi
        .cancel({
          activityId: this.activityId,
          userId: item.userId
        })
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.loading = false
          this.$message.error(e.message)
        })
    },
    // 重置列表
    queryFormField() {
      const that = this
      activityFormFieldApi
        .all({ formId: this.formId, enable: true })
        .then((res) => {
          if (res.code === 0) {
            console.log('远', res.data)
            that.formColums = [
              {
                key: 'index',
                width: 48,
                customRender: ({ index }) =>
                  this.$refs.tableReg.tableIndex + index
              }
            ]
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].type === 'file' || res.data[i].type === 'image') {
                that.formColums.push({
                  title: res.data[i].label,
                  dataIndex: res.data[i].fieldId,
                  slots: { customRender: 'img' }
                })
              } else {
                that.formColums.push({
                  title: res.data[i].label,
                  dataIndex: res.data[i].fieldId,
                  sorter: true
                })
              }
            }
            that.resetColums()
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询
    resetColums() {
      this.columns = this.formColums.concat([
        {
          title: '状态',
          dataIndex: 'state',
          sorter: true
        },
        {
          title: '付款金额',
          dataIndex: 'payPrice',
          sorter: true
        },
        {
          title: '付款时间',
          dataIndex: 'payTime',
          sorter: true
        },
        {
          title: '付款状态',
          dataIndex: 'payState',
          sorter: true
        },
        {
          title: '报名时间',
          dataIndex: 'createTime',
          sorter: true
        },
        {
          title: '操作',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ])
      console.log(this.columns)
      this.reload()
    },
    openRegSetting() {
      this.showRegSetting = true
      console.log('sss', this.formId)
    },
    doneRegSetting(val) {
      if (val === 'hideRegSetting') {
        this.showRegSetting = false
      } else if (val === 'resetField') {
        this.queryFormField()
      }
    }
  }
}
</script>
<style  scoped>
.image {
  width: 120px;
  height: 90px;
}

.image :deep(.ant-image-img) {
  height: 100%;
}

:deep(.ud-tool) {
  display: none;
}
</style>
